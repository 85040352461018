<template>
  <div class="flex">
    <v-btn
      v-if="!noReturn && hasBackNav"
      class="mr-2 bg-transparent"
      size="30"
      @click="back"
      tile
      icon
    >
      <Icon name="jam:arrow-left" size="20" />
    </v-btn>
    <div>
      <h1 class="text-5 flex-align">
        <span class="max-w-200px md:max-w-600px line-height-tight">
          <slot name="title">{{ title }}</slot>
        </span>
        <slot name="append-title"></slot>
      </h1>
      <div v-if="subtitle" class="text-3.5 opacity-70">{{ subtitle }}</div>
      <div>
        <slot name="under-title"></slot>
      </div>
    </div>
    <div
      class="h-full flex-grow flex-align justify-end gap-2"
      :class="{ 'pt-1': hasBackNav }"
    >
      <slot></slot>
      <AppActions
        v-if="actions"
        @action="handleAction"
        :actions="actions"
        :namespace="actionsNamespace"
        :broadcast="broadcastActions"
        :mini="actionsMini"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useBreadcrumbs } from "../composables/breadcrumbs";
import type { AppAction } from "../services/actions/types";
import type { RouteLocationRaw } from "vue-router";

export default defineComponent({
  name: "PageHeading",
  props: {
    title: { type: String },
    subtitle: { type: String },
    actions: { type: Array as PropType<AppAction[]> },
    noReturn: { type: Boolean, default: false },
    actionsNamespace: { type: String },
    broadcastActions: { type: Boolean, default: false },
    actionsMini: { type: Boolean, default: false },
    returnTo: { type: [String, Object] as PropType<RouteLocationRaw> },
  },
  setup(props) {
    const { back: navBack, hasBackNav } = useBreadcrumbs();
    const router = useRouter();

    const back = () => {
      if (props.returnTo) {
        router.push(props.returnTo);
      } else {
        return navBack();
      }
    };

    return {
      back,
      hasBackNav,
    };
  },
  methods: {
    handleAction(item: any) {
      this.$emit("action:" + item.key);
    },
  },
});
</script>
