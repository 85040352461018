import { capitalize } from "lodash";
import type { RouteLocationRaw } from "vue-router";

export const useBreadcrumbs = () => {
  const route = useRoute();
  const router = useRouter();

  /**
   * Currently `router.resolve` resolves /location/index
   * as /locations/[handle].
   */
  const isResolveError = (name: string, params: any) => {
    if (!params) {
      return false;
    }
    const keys = Object.keys(params);
    if (keys.length != 1) {
      return false;
    }
    const n = params[keys[0]] + "-" + keys[0];
    return name == n;
  };

  const crumbs = computed(() => {
    const paths = route.path
      .substring(1)
      .replace(/\/$/, "") // trailing slashes
      .split("/");

    return paths.map((name, i) => {
      const p = paths.slice(0, ++i);
      const { name: n, params, query } = router.resolve(p.join("/"));
      const last = i >= paths.length;

      const text = name.split("-").map(capitalize).join(" ");

      return {
        text,
        name,
        last,
        disabled: n === null || last,
        to:
          n && !isResolveError(n as string, params)
            ? { name: n, params, query }
            : { name: p.join("-") },
      };
    });
  });

  const hasBackNav = computed(() => crumbs.value.length > 1);

  const back = () => {
    let routeTo: RouteLocationRaw = "/";
    if (crumbs.value.length > 1) {
      for (let i = crumbs.value.length - 2; i >= 0; i--) {
        const dest = crumbs.value[i];
        try {
          router.resolve(dest.to); // make sure route is valid
          const isActive = route.matched.some((r) => r.name === dest.to.name);
          if (!isActive) {
            routeTo = dest.to;
            break;
          }
        } catch {
          //
        }
      }
    }
    router.push(routeTo);
  };

  return {
    hasBackNav,
    crumbs,
    back,
  };
};
